import React, { useState } from 'react';
import { Upload, Modal, Button, Select, Tooltip, Icon, Form, Input, DatePicker, Layout, Space, message, ConfigProvider, Table  } from 'antd';
import SoundMeter from './soundmeter';
import PropTypes from 'prop-types';
import SettingsIcon from "mdi-react/SettingsIcon";
import "../../styles/css/setting.scss";
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios'
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
//视频
import { FileSearchOutlined} from '@ant-design/icons';
let video;
const { RangePicker } = DatePicker;
const Option = Select.Option;
const { Header, Content, Sider } = Layout;
const { Search } = Input;
const closeMediaStream = function (stream) {

    if (!stream) {
        return;
    }
    if (MediaStreamTrack && MediaStreamTrack.prototype && MediaStreamTrack.prototype.stop) {
        var tracks, i, len;

        if (stream.getTracks) {
            tracks = stream.getTracks();
            for (i = 0, len = tracks.length; i < len; i += 1) {
                tracks[i].stop();
            }
        } else {
            tracks = stream.getAudioTracks();
            for (i = 0, len = tracks.length; i < len; i += 1) {
                tracks[i].stop();
            }

            tracks = stream.getVideoTracks();
            for (i = 0, len = tracks.length; i < len; i += 1) {
                tracks[i].stop();
            }
        }
        // Deprecated by the spec, but still in use.
    } else if (typeof stream.stop === 'function') {
        console.log('closeMediaStream() | calling stop() on the MediaStream');
        stream.stop();
    }
}

// Attach a media stream to an element.
const attachMediaStream = function (element, stream) {
    element.srcObject = stream;
};

export default class Report extends React.Component {

    constructor(props) {



        super(props)
        let settings = props.settings;
        this.state = {
            startTime:"",
            endTime:"",

            reportData: [],
            loading: true,
            page: 1,
            pageSize: 10,
            total: 0,
            selRoomName: "",
            numberOfPages: "",


            streams: [],
            tex: "2qq",
            id: '',
            imageUrl1: '',
            imageUrl2: '',
            pathologyNumber: '',
            patientName: '',
            patientSex: '',
            patientAge: '',
            outpatientNumber: '',
            bedNumber: '',
            inspectionDepartment: '',
            clinicDiagnosis: '',
            inspectionDoctor: '',
            grossExamination: '',
            microscopeSee: '',
            pathologicDiagnosis: '',
            formData: {},
            visible: false,
            videoDevices: [],
            audioDevices: [],
            audioOutputDevices: [],
            resolution: settings.resolution,
            bandwidth: settings.bandwidth,
            selectedAudioDevice: settings.selectedAudioDevice,
            selectedVideoDevice: settings.selectedVideoDevice,
            selectedVideoDeviceLocal: settings.selectedVideoDeviceLocal,
            codec: settings.codec,
            searchTerm: '',
            setSearchTerm: ''
        }
        this.timerID2 = null; // 存储定时器ID
        try {
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            window.audioContext = new AudioContext();
        } catch (e) {
            console.log('Web Audio API not supported.');
        }
        let fileList = []
        const { imgs } = this.props
        if (imgs && imgs.length > 0) {
            fileList = imgs.map((img, index) => ({
                uid: -index,
                name: img,
                status: 'done',
                url: BASE_IMG_URL + img
            }))
        }
    }


    getReport() {
        const api = axios.create({
            // baseURL: 'http://127.0.0.1:5011'
            baseURL: 'https://xyreport.xytechsz.com/report'
        });

        api.get('/report/getReportData', {
            params: {

                roomName:sessionStorage.getItem('roomName')
            }
        })
            .then(response => {
                // 处理请求成功的逻辑

                this.setState({ id: response.data.data.id });
                this.setState({ imageUrl1: response.data.data.pathologicalImage1 });
                this.setState({ imageUrl2: response.data.data.pathologicalImage2 });
                this.setState({ pathologyNumber: response.data.data.pathologyNumber });
                this.setState({ patientName: response.data.data.patientName });
                this.setState({ patientSex: response.data.data.patientSex });
                this.setState({ patientAge: response.data.data.patientAge });
                this.setState({ outpatientNumber: response.data.data.outpatientNumber });
                this.setState({ bedNumber: response.data.data.bedNumber });
                this.setState({ inspectionDepartment: response.data.data.inspectionDepartment });
                this.setState({ clinicDiagnosis: response.data.data.clinicDiagnosis });
                this.setState({ inspectionDepartment: response.data.data.inspectionDepartment });
                this.setState({ clinicDiagnosis: response.data.data.clinicDiagnosis });
                this.setState({ inspectionDoctor: response.data.data.inspectionDoctor });
                this.setState({ bedNumber: response.data.data.bedNumber });
                this.setState({ grossExamination: response.data.data.grossExamination });
                this.setState({ microscopeSee: response.data.data.microscopeSee });
                this.setState({ pathologicDiagnosis: response.data.data.pathologicDiagnosis });




                this.setState({ reportTime: response.data.data.reportTime });
                this.setState({ inspectionTime: response.data.data.inspectionTime });

            })
            .catch(error => {
                // 处理请求失败的逻辑
                console.error(error);
            });
    }
    componentDidMount() {
       
        this.getReport()
    }
    updateInputDevices = () => {
        return new Promise((pResolve, pReject) => {
            let videoDevices = [];
            let audioDevices = [];
            let audioOutputDevices = [];
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    for (let device of devices) {
                        if (device.kind === 'videoinput') {
                            videoDevices.push(device);
                        } else if (device.kind === 'audioinput') {
                            audioDevices.push(device);
                        } else if (device.kind === 'audiooutput') {
                            audioOutputDevices.push(device);
                        }
                    }
                }).then(() => {
                    let data = { videoDevices, audioDevices, audioOutputDevices };
                    pResolve(data);
                });
        });
    }


    //获取视频成功
    handleSuccess = (stream) => {
        console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
        window.stream = stream;
        //将视频源指定为视频流
        video.srcObject = stream;
    }
    //错误处理
    handleError(error) {
        console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
    }
    soundMeterProcess = () => {
        var val = (window.soundMeter.instant.toFixed(2) * 348) + 1;
        this.setState({ audioLevel: val });
        if (this.state.visible)
            setTimeout(this.soundMeterProcess, 100);
    }
    soundMeterProcess2 = () => {
        var val = (window.soundMeter.instant.toFixed(2) * 348) + 1;
        this.setState({ audioLevel: val });
        if (this.state.visible)
            setTimeout(this.soundMeterProcess2, 100);
    }
    startPreview = () => {
        if (window.stream) {
            // closeMediaStream(window.stream);
        }
        let videoElement = this.refs['previewVideo'];
        let audioSource = this.state.selectedAudioDevice;
        let videoSource = this.state.selectedVideoDevice;


        sessionStorage.setItem('audioSource', audioSource);
        sessionStorage.setItem('videoSource', videoSource);

        this.soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
        let soundMeterProcess = this.soundMeterProcess;
        let constraints = {
            audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
            video: { deviceId: videoSource ? { exact: videoSource } : undefined }
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
                window.stream = stream; // make stream available to console
                //videoElement.srcObject = stream;
                attachMediaStream(videoElement, stream);
                soundMeter.connectToSource(stream);
                setTimeout(soundMeterProcess, 100);
                // Refresh button list in case labels have become available
                return navigator.mediaDevices.enumerateDevices();
            })
            .then((devces) => { })
            .catch((erro) => { });
    }
    startPreview2 = () => {
        if (window.stream) {
            // closeMediaStream(window.stream);
        }

        let videoElement2 = this.refs['previewVideo2'];
        let videoSource = this.state.selectedVideoDeviceLocal;
        let selectedVideoDeviceLocal = this.state.selectedVideoDeviceLocal;
        sessionStorage.setItem('selectedVideoDeviceLocal', selectedVideoDeviceLocal);
        this.soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
        let soundMeterProcess = this.soundMeterProcess2;
        let constraints = {
            video: { deviceId: videoSource ? { exact: videoSource } : undefined }
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
                window.stream = stream; // make stream available to console
                //videoElement.srcObject = stream;

                attachMediaStream(videoElement2, stream);
                soundMeter.connectToSource(stream);
                setTimeout(soundMeterProcess, 100);
                // Refresh button list in case labels have become available
                return navigator.mediaDevices.enumerateDevices();
            })
            .then((devces) => { })
            .catch((erro) => { });
    }
    stopPreview = () => {
        if (window.stream) {
            closeMediaStream(window.stream);
        }
    }

    componentWillUnmount() {

    }

    showModal = () => {

        this.setState({
            visible: true,reportData:[],total:0,page:1
        });

        this.getReport()
        setTimeout(this.startPreview, 100);
        setTimeout(this.startPreview2, 100);


    }

    handleOk = (e) => {

        this.setState({
            visible: false,
        });



    }


    // handleCancel = (e) => {
    //     this.setState({
    //         visible: false,
    //     });
    //     this.stopPreview();
    // }

    // handleAudioDeviceChange = (e) => {
    //     this.setState({ selectedAudioDevice: e });
    //     setTimeout(this.startPreview, 100);
    // }

    handleVideoDeviceChange = (e) => {
        this.setState({ selectedVideoDevice: e });
        setTimeout(this.startPreview, 100);
    }
    handleVideoDeviceLocalChange = (e) => {
        this.setState({ selectedVideoDeviceLocal: e });
        setTimeout(this.startPreview2, 100);
    }
    handleResolutionChange = (e) => {
        this.setState({ resolution: e });
    }

    handleVideoCodeChange = (e) => {
        this.setState({ codec: e });
    }

    handleBandWidthChange = (e) => {
        this.setState({ bandwidth: e });
    }
    onFinish = (values) => {
        console.log('Received values from form:', values);
        // 在这里可以对表单提交的值进行处理，比如发送给后端处理
    };
    handleInputChange1 = (values) => {
        console.log('Received values from form:', values);
        // 在这里可以对表单提交的值进行处理，比如发送给后端处理
    };
    handleSearch = (values) => {
        console.log('Received values from form:', values);
        // 在这里可以对表单提交的值进行处理，比如发送给后端处理
    };


    getImgs = () => {
        return this.state.fileList.map(file => file.name)
    }

    handleCancel = () => {
        this.handleSubmit()
        this.setState({
            visible: false,
        });
    }

    //截屏处理
    takeSnap1 = async (e) => {
        this.props.getImage();
        this.setState({
            imageUrl1: sessionStorage.getItem("base64Image")
        });
    }
    //截屏处理
    takeSnap2 = async (e) => {
        this.props.getImage();
        this.setState({
            imageUrl2: sessionStorage.getItem("base64Image")
        });
    }
    handleChange = () => {

    }
    handledy = (event) => {
        event.preventDefault();
        const printableContent = document.getElementById("myPrintableContent").innerHTML;
        const printWindow = window.open("", "Print");
        printWindow.document.write(`<html><head><title>诊断报告</title> <style> hr {      border: none;      border-top: 3px solid black;      margin: 20px 0; }p { word-wrap: break-word;  word-break: break-all;  white-space: pre-wrap; } </style></head><body>${printableContent}</body></html>`);
        printWindow.document.close();
        printWindow.print();
    }
    handleSubmit = () => {
        console.log("提交")
        const api = axios.create({
            baseURL: 'https://xyreport.xytechsz.com/report'

            // baseURL: 'http://127.0.0.1:5011'
        });

        api.post('/report/sendReport', {

            id: this.state.id,
            pathologicalImage1: this.state.imageUrl1,
            pathologicalImage2: this.state.imageUrl2,
            pathologyNumber: this.state.pathologyNumber,
            patientName: this.state.patientName,
            patientSex: this.state.patientSex,
            patientAge: this.state.patientAge,
            outpatientNumber: this.state.outpatientNumber,
            bedNumber: this.state.bedNumber,
            inspectionDepartment: this.state.inspectionDepartment,
            clinicDiagnosis: this.state.clinicDiagnosis,
            inspectionDoctor: this.state.inspectionDoctor,
            grossExamination: this.state.grossExamination,
            microscopeSee: this.state.microscopeSee,
            pathologicDiagnosis: this.state.pathologicDiagnosis,
            roomName: sessionStorage.getItem('roomName'),
            reportTime: this.state.reportTime?.trim() ?this.state.reportTime:null,
            inspectionTime: this.state.inspectionTime?.trim() ?this.state.inspectionTime:null,

        })
            .then(response => {
                // 处理请求成功的逻辑

                message.success('上传成功', 2);

                this.getReport()


            })
            .catch(error => {
                // 处理请求失败的逻辑
                
                console.error(error);
            });

            this.setState({
                visible: false,
            });   

    }

    handleTxt = (e) => {
        this.setState({ tex: e.target.value })

    }
    pathologyNumberonChange = (e) => {
        this.setState({ pathologyNumber: e.target.value })

    }
    patientNameonChange = (e) => {
        this.setState({ patientName: e.target.value })

    }
    patientSexonChange = (e) => {
        this.setState({ patientSex: e.target.value })

    }
    patientAgeonChange = (e) => {
        this.setState({ patientAge: e.target.value })

    }
    outpatientNumberonChange = (e) => {
        this.setState({ outpatientNumber: e.target.value })

    }
    bedNumberonChange = (e) => {
        this.setState({ bedNumber: e.target.value })

    }
    inspectionDepartmentonChange = (e) => {
        this.setState({ inspectionDepartment: e.target.value })

    }
    clinicDiagnosisonChange = (e) => {
        this.setState({ clinicDiagnosis: e.target.value })

    }
    inspectionDoctoronChange = (e) => {
        this.setState({ inspectionDoctor: e.target.value })

    }
    grossExaminationonChange = (e) => {
        this.setState({ grossExamination: e.target.value })

    }
    microscopeSeeonChange = (e) => {
        this.setState({ microscopeSee: e.target.value })

    }
    pathologicDiagnosisonChange = (e) => {
        this.setState({ pathologicDiagnosis: e.target.value })

    }
    selRoomNameOnChange = (e) => {
        this.setState({ selRoomName: e.target.value })

    }
    selectOnClick = (e) => {
        this.fetchData()
    }
    addReport = (e) => {
        this.setState({ id: "" });
        this.setState({ imageUrl1: "" });
        this.setState({ imageUrl2: "" });
        this.setState({ pathologyNumber: "" });
        this.setState({ patientName: "" });
        this.setState({ patientSex: "" });
        this.setState({ patientAge: "" });
        this.setState({ outpatientNumber: "" });
        this.setState({ bedNumber: "" });
        this.setState({ inspectionDepartment: "" });
        this.setState({ clinicDiagnosis: "" });
        this.setState({ inspectionDepartment: "" });
        this.setState({ clinicDiagnosis: "" });
        this.setState({ inspectionDoctor: "" });
        this.setState({ bedNumber: "" });
        this.setState({ grossExamination: "" });
        this.setState({ microscopeSee: "" });
        this.setState({ pathologicDiagnosis: "" });
        this.setState({ reportTime: "" });
        this.setState({ inspectionTime: "" });

    }
    handleReportTimeChange = (date) => {
        this.setState({ reportTime: date.format('YYYY-MM-DD HH:mm:ss') });
    }
    handleInspectionTimeTimeChange = (date) => {
        this.setState({ inspectionTime: date.format('YYYY-MM-DD HH:mm:ss') });

    }

    fetchData = () => {
        const { page, pageSize, selRoomName,startTime,endTime } = this.state;
        this.setState({ loading: true });
          console.log(startTime)  
          console.log(endTime)  
        const api = axios.create({
            // baseURL: 'http://127.0.0.1:5011'
            baseURL: 'https://xyreport.xytechsz.com/report'
        });
        api.get('/report/selectPage', {
            params: {
                startTime: startTime ,
                endTime:endTime,
                pathologyNumber: "",
                patientName: "",
                pageSize: pageSize,
                pageNum: page,
                roomName: selRoomName
            }
        })
            .then(response => {
                // 处理请求成功的逻辑

                this.setState({ reportData: response.data.data.list, loading: false, total: response.data.data.total, numberOfPages: Math.ceil(response.data.data.total / pageSize) });




                // this.setState({ inspectionTime: response.data.data.inspectionTime });

            })
            .catch(error => {
                // 处理请求失败的逻辑
                console.error(error);
            });

        // const newData = Array.from({ length: pageSize }).map((_, index) => {
        //     const id = (page - 1) * pageSize + index + 1;
        //     return { id, name: `Item ${id}` };
        // });
        // this.setState({ data: newData, loading: false, total: 100 }); // 假设总共有100条记录
    };

    handleTableChange = (pagination) => {
        this.setState({ page: pagination.current, pageSize: pagination.pageSize }, () => {

            this.fetchData();
        });
    };
    handlePrevPage = () => {
        const { page } = this.state;
        if (page > 1) {
            this.setState({ page: page - 1 }, () => {

                this.fetchData();
            });
        }
    };

    handleNextPage = () => {
        const { page } = this.state;
        if (page < this.state.numberOfPages) {
            this.setState({ page: page + 1 }, () => {
                this.setState({ page: page + 1 })

                this.fetchData();
            });
        }
    };
    changeRoomId = (e) => {
        const api = axios.create({
            // baseURL: 'http://127.0.0.1:5011'
            baseURL: 'https://xyreport.xytechsz.com/report'

            
        });

        api.post('/report/changeRoomId', {
            roomName: sessionStorage.getItem('roomName'),
            patientId: e

        })
            .then(response => {
                // 处理请求成功的逻辑


                this.getReport()


            })
            .catch(error => {
                // 处理请求失败的逻辑
                console.error(error);
            });
    };

    changeRangePicker = (value, dateString) => {
        const [startTime, endTime] = dateString;
        this.setState({startTime:startTime,endTime:endTime})
     
        // this.setState({ inspectionTime: e.format('YYYY-MM-DD HH:mm:ss') });
    };
    onOkRangePicker = (e) => {
        console.log(e)
       
    };

   
    render() {
        const { reportData, loading, page, pageSize, total } = this.state;


        return (
            <div>
                {
                    <Tooltip title='报告'>
                        <Button size="large" type="link" ghost onClick={this.showModal}>
                            
							 <FileSearchOutlined style={{ fontSize: '18px', color: '#FFFFFF' }}  />
							
                            <p	>报告</p>
                        </Button>
                    </Tooltip>
                }
                <Modal
                    title='发送报告'
                    visible={this.state.visible}
                    onCancel={this.handleCancel}

                    width="80%"
                    className="full-modal"
                    footer={[

                        <Button key='continue' onClick={this.handledy}>打印</Button>,
                        <Button key='Submit' onClick={this.handleSubmit}>发送</Button>,

                    ]}

                >
                    <div style={{ display: 'none' }}>
                        <div id="myPrintableContent" className="report-container">
                            <h2 style={{ textAlign: 'center' }}>医院诊断报告单</h2>

                            <div className="patient-info">
                                <span >病理号: </span>
                                <span >{this.state.pathologyNumber}</span>

                                <hr />
                                <table width="100%">

                                    <tbody>

                                        <tr>
                                            <td>姓名</td>
                                            <td >
                                                <p>{this.state.patientName}</p>
                                            </td>
                                            <td>性别</td>
                                            <td >
                                                <p>{this.state.patientSex}</p>
                                            </td>
                                            <td>年龄</td>
                                            <td >
                                                <p>{this.state.patientAge}</p>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>门诊/住院号</td>
                                            <td>
                                                <p>{this.state.outpatientNumber}</p>
                                            </td>
                                            <td>床号</td>
                                            <td >
                                                <p>{this.state.bedNumber}</p>
                                            </td>
                                            <td>送检科室</td>
                                            <td >
                                                <p>{this.state.inspectionDepartment}</p>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>临床诊断</td>
                                            <td >
                                                <p>{this.state.clinicDiagnosis}</p>
                                            </td>
                                            <td>送检医生</td>
                                            <td >
                                                <p>{this.state.inspectionDoctor}</p>
                                            </td>
                                        </tr>
                                        <tr>


                                            <td>送检日期</td>
                                            <td >
                                                <p>{this.state.inspectionTime}  </p>
                                            </td>
                                            <td>报告日期</td>
                                            <td >
                                                <p>{this.state.reportTime}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <hr style={{ borderTop: '1px solid black' }} />
                            </div>
                            <div className="diagnosis">
                                <h3>病理图像：</h3>

                                <img width="200px" src={this.state.imageUrl1} alt={`病理图像1`} />
                                <img style={{ marginLeft: '20px' }} width="200px" src={this.state.imageUrl2} alt={`病理图像2`} />

                                <h4>大体检查：</h4>
                                <p>{this.state.grossExamination}</p>
                                <h4 style={{ marginTop: '80px' }}>镜下所见：</h4>
                                <p >{this.state.microscopeSee}</p>
                                <h4 style={{ marginTop: '80px' }}>病理诊断：</h4>
                                <p >{this.state.pathologicDiagnosis}</p>
                            </div>

                        </div>
                    </div>

                    <Layout>

                        <Layout>

                            <Sider width={300} theme="light">
                                <div className='leftmenu'>
                                    <div>

                                    </div>
                                    <div>
                                        <input
                                            className='inputSearch'
                                            type="text"
                                            value={this.state.selRoomName}
                                            onChange={this.selRoomNameOnChange}

                                            placeholder="请输入关键字"
                                        />

                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                            <ConfigProvider locale={zhCN}>
                                                <RangePicker
                                                    
                                                    format="YYYY-MM-DD "
                                                    placeholder={['开始时间', '结束时间']}
                                                    onChange={this.changeRangePicker}
                                                    onOk={ this.onOkRangePicker}
                                                    style={{width:'200px'}}
                                                />

                                            </ConfigProvider>

                                        </div>

                                    <Button style={{ marginTop: "10px" }} className='btnSearch' onClick={this.selectOnClick} type="primary" size='small'  >查询</Button>
                                    <div style={{ marginTop: "10px" }}>

                                        <div>
                                            <div style={{
                                                marginTop: '24px',
                                                width: '250px',
                                                height: '32rem',
                                                background: '#dcdcdc',
                                                fontSize: '1rem',
                                                lineHeight: '3.2rem',
                                                textAlign: 'center'
                                            }}>
                                                {reportData.map((item, index) => (
                                                    <p onClick={() => this.changeRoomId(item.id)} key={item.id} style={{ backgroundColor: index % 2 === 0 ? '#78b3ea' : '#c8ede8' }}>
                                                        {item.patientName}-{item.pathologyNumber}-{item.roomName}
                                                    </p>
                                                ))}
                                            </div>
                                            <div style={{ marginTop: '24px', textAlign: 'center' }}>
                                                <Button disabled={page === 1} onClick={this.handlePrevPage}>
                                                    上一页
                                                </Button>
                                                <span style={{ margin: '0 12px' }}>{`第 ${page} 页`}</span>
                                                <Button disabled={page === this.state.numberOfPages} onClick={this.handleNextPage}>
                                                    下一页
                                                </Button>
                                            </div>
                                            <Table
                                                style={{ display: 'none' }}
                                                dataSource={reportData}
                                                columns={[{ title: '', dataIndex: 'empty' }]}
                                                rowKey="id"
                                                loading={loading}
                                                pagination={false}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </Sider>
                            <Layout>
                                <Content style={{ padding: '24px' }}>
                                    <Button className='btnSearch' onClick={() => this.addReport()} type="primary" size='small' style={{ marginBottom: "20px" }} >新建</Button>
                                    <Form layout="vertical">
                                        <table>

                                            <tbody>
                                                <tr>
                                                    <td>病理号</td>
                                                    <td className="custom-td">
                                                        <input type="text" value={this.state.pathologyNumber} onChange={this.pathologyNumberonChange} placeholder="请输入病理号" width="100px" />
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>姓名</td>
                                                    <td className="custom-td">
                                                        <input type="text" value={this.state.patientName} onChange={this.patientNameonChange} placeholder="请输入姓名" width="100px" />
                                                    </td>
                                                    <td>性别</td>
                                                    <td className="custom-td">
                                                        <input type="text" value={this.state.patientSex} onChange={this.patientSexonChange} placeholder=" " width="100px" />
                                                    </td>
                                                    <td>年龄</td>
                                                    <td className="custom-td">
                                                        <input type="text" value={this.state.patientAge} onChange={this.patientAgeonChange} placeholder="请输入年龄" width="100px" />
                                                    </td>
                                                    <td>送检日期</td>
                                                    <td className="custom-td">
                                                        <ConfigProvider locale={zhCN}>
                                                            <DatePicker value={moment(this.state.inspectionTime, 'YYYY-MM-DD HH:mm:ss')} showTime format="YYYY-MM-DD HH:mm:ss" onChange={this.handleInspectionTimeTimeChange} placeholder="请选择送检日期" />
                                                        </ConfigProvider>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>门诊/住院号</td>
                                                    <td className="custom-td">
                                                        <input type="text" value={this.state.outpatientNumber} onChange={this.outpatientNumberonChange} placeholder="请输入门诊/住院号" width="100px" />
                                                    </td>
                                                    <td>床号</td>
                                                    <td className="custom-td">
                                                        <input type="text" value={this.state.bedNumber} onChange={this.bedNumberonChange} placeholder="请输入床号" width="100px" />
                                                    </td>
                                                    <td>送检科室</td>
                                                    <td className="custom-td">
                                                        <input type="text" value={this.state.inspectionDepartment} onChange={this.inspectionDepartmentonChange} placeholder="请输入送检科室" width="100px" />
                                                    </td>
                                                    <td>报告日期</td>
                                                    <td className="custom-td">
                                                        <ConfigProvider locale={zhCN}>
                                                            <DatePicker value={moment(this.state.reportTime, 'YYYY-MM-DD HH:mm:ss')} showTime format="YYYY-MM-DD HH:mm:ss" onChange={this.handleReportTimeChange} placeholder="请选择报告日期" />
                                                        </ConfigProvider>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>临床诊断</td>
                                                    <td className="custom-td">
                                                        <input type="text" value={this.state.clinicDiagnosis} onChange={this.clinicDiagnosisonChange} placeholder="请输入临床诊断" width="100px" />
                                                    </td>
                                                    <td>送检医生</td>
                                                    <td className="custom-td">
                                                        <input type="text" value={this.state.inspectionDoctor} onChange={this.inspectionDoctoronChange} placeholder="请输入送检医生" width="100px" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Form.Item style={{ marginTop: "10px", }} label="病例图像" name="diagnosis" rules={[{ required: true, message: '请输入诊断信息' }]}>
                                            <img className="small-canvas1" src={this.state.imageUrl1} ref='canvas1' />
                                            <Button className="button" onClick={this.takeSnap1}>截屏</Button>
                                            <img className="small-canvas1" src={this.state.imageUrl2} ref='canvas2' />
                                            <Button className="button" onClick={this.takeSnap2}>截屏</Button>
                                        </Form.Item>
                                        <Form.Item style={{ marginTop: "100px", }} label="大体检查" name="diagnosis" rules={[{ required: true, message: '请输入诊断信息' }]}>
                                            <Input.TextArea value={this.state.grossExamination} onChange={this.grossExaminationonChange} placeholder="请输入诊断信息" rows={8} />
                                        </Form.Item>
                                        <Form.Item label="镜下所见" name="diagnosis" rules={[{ required: true, message: '请输入诊断信息' }]}>
                                            <Input.TextArea value={this.state.microscopeSee} onChange={this.microscopeSeeonChange} placeholder="请输入诊断信息" rows={8} />
                                        </Form.Item>
                                        <Form.Item label="病理诊断" name="diagnosis" rules={[{ required: true, message: '请输入病理诊断信息' }]}>
                                            <Input.TextArea value={this.state.pathologicDiagnosis} onChange={this.pathologicDiagnosisonChange} placeholder="请输入病理诊断" rows={8} />
                                        </Form.Item>

                                    </Form>
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>



                </Modal>
            </div>
        );
    }
}


Report.propTypes = {
    onMediaSettingsChanged: PropTypes.func
}


