import React from 'react';
import PropTypes from "prop-types";
import ReactDOM from 'react-dom';
import { Modal, Button, Tooltip, Input,Icon,message } from 'antd';
import DotsVerticalIcon from "mdi-react/ShareIcon";
export default class ShareMeeting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
         };
    }
    showModal = () => {
        this.setState({
            visible: true,
        });

        let loginInfo = this.props.loginInfo;
        let host = window.location.host;
        let url = window.location.protocol + "//" + host + "/?room=" + loginInfo.roomId;
        this.setState({ url });
    }
    handleOk = (e) => {
		 navigator.clipboard.writeText(this.state.url)
		            .then(() => {
		                message.success('链接已复制到剪贴板');
		                this.handleCancel(); // 关闭模态框
		            })
		            .catch(err => {
		                message.error('复制链接失败');
		              
		            });
        
    }
    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    }

    onFocus = (e) => {
        ReactDOM.findDOMNode(e.target).select();
    }

    render() {
        return (
            <div className="app-header-tool-container">
                <Tooltip title='分享会议'>
                <Button ghost size="large" type="link" onClick={this.showModal}>
                  <Icon
                    component={DotsVerticalIcon}
                    style={{ display: "flex", justifyContent: "center" }}
                  />
				  <p>分享会议</p>
                </Button>
                </Tooltip>
                <Modal
                    title='分享会议'
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText='复制链接'
                    cancelText='取消'>
                    <div>
                        <div>
                            <span>复制链接给您的朋友</span>
                            <Input onFocus={this.onFocus} readOnly={true} value={this.state.url} />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

ShareMeeting.propTypes = {
    roomInfo: PropTypes.any,
}

